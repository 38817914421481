<template>
  <div class="error-wrapper">
    <div class="message">
      {{ $t('global.errors.expiredToken') }}
    </div>
    <app-button-layout color="secondary" @click="redirectToOscar">
      {{ $t('global.navigation.goToLogin') }}
    </app-button-layout>
  </div>
</template>

<script>
import redirectToOscar from '@/utils/redirectToOscar'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'tokenError',
  components: { AppButtonLayout },
  setup () {
    return { redirectToOscar }
  },
  mounted () {
    this.$store.dispatch('user/logout')
  }
}
</script>

<style lang="scss" scoped>
.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .app-button-layout {
    margin-top: $space-m;
  }
}
</style>
